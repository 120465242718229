<template>
  <div id="page-branch-list">
    <div class="vx-row mb-4">
      <div class="vx-col w-full">
        <div class="vx-card p-2">
          <div class="vx-row">
            <!-- <div class="vx-col md:w-1/4 w-full">
              <label class="vs-input--label">{{ $t('minioStorage.dateRange') }}</label>
              <date-range-picker
                  ref="picker"
                  :locale-data="{format: 'dd/mm/yyyy' }"
                  :opens="'right'"
                  v-model="dateRange"
                  class="w-full"
                  >
              </date-range-picker>
            </div> -->
            <div class="vx-col md:w-1/4 w-full">
              <label class="vs-input--label">{{ $t('cardCustomer.cardType') }}</label>
              <v-select v-model="customerTypeModel" :options="customerTypes"></v-select>
            </div>
            <div class="vx-col md:w-1/4 w-full">
              <label class="vs-input--label">{{ $t('cardCustomer.cardStatus') }}</label>
              <v-select v-model="customerStatusModel" :options="customerStatus"></v-select>
            </div>
            <div class="vx-col md:w-1/4 w-full mt-6">
              <div class="w-full flex ">
                <vs-button icon-pack="feather" icon="icon-search" class="" @click="filterDataCard">{{ $t('cardCustomer.search') }}</vs-button>
                <vs-button icon-pack="feather" icon="icon-plus" class="ml-2" @click="addCardCustomer">{{ $t('cardCustomer.addCard') }}</vs-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col md:w-1/6 w-full mt-4" v-for="(card, indexCard) in listCards" :key="indexCard" style="min-height:300px">
        <card-customer :data="card" :isEdit="true"/>
      </div>
    </div>

      <div class="vx-row mt-4">
        <div class="vx-col w-full">
            <vs-pagination
                :total="totalPages"
                :max="maxPageNumbers"
                @change="loadPageCard"
                v-model="currentPage"/>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import CardCustomer from "./card/CardCustomer.vue"
import DateRangePicker from '../../../components/vue2-daterange-picker/components/DateRangePicker.vue'
export default {
  components: {
    CardCustomer,
    DateRangePicker,
    vSelect
  },
  watch: {
    '$store.state.isDeleteCardCustomer'(val) {
      if (val) {
          this.initScreen();
          this.$store.commit('DELETE_CARD', false);
      }
    }
  },
  computed: {
    customerTypeModel: {
      get(){
        let object = this.customerTypes.find(el => el.value == this.customer.cardStaffType);
        if (!object) return;
        return {
          label: object.label,
          value: object.value
        }
      },
      set(object){
        this.customer.cardStaffType = object ? object.value : null;
      }
    },
    customerStatusModel: {
      get(){
        let object = this.customerStatus.find(el => el.value == this.customer.cardStatus);
        if (!object) return;
        return {
          label: object.label,
          value: object.value
        }
      },
      set(object) {
        this.customer.cardStatus = object ? object.value : null;
      }
    }
  },
  data(){
    return {
      pageNumber: 0,
      pageSize: 12,
      customerTypes: [
        {value: null, label: this.$t('cardCustomer.allCard')},
        {value: 0, label: this.$t('cardCustomer.customer')},
        {value: 1, label: this.$t('cardCustomer.employee')},
        {value: 2, label: this.$t('cardCustomer.unknown')},
      ],
      customerStatus: [
        {value: null, label: this.$t('cardCustomer.allCard')},
        {value: 0, label: this.$t('cardCustomer.unused')},
        {value: 1, label: this.$t('cardCustomer.used')}
      ],
      customer:{
        cardStaffType: null,
        cardStatus: null
      },
      totalPages: 0,
      maxPageNumbers: 5,
      currentPage: 1,
      listCards: [],
      dateRange: {
        endDate: new Date(),
        startDate: (() => {
        let date = new Date();
        date.setDate(date.getDate() - 6);
        date.setHours(0, 0, 0);
        return date;
        })()
      }
    }
  },
  methods: {
    filterDataCard: function(){
      this.getDataCard();
    },
    addCardCustomer: function(){
      this.$router.push('/user/card-customer-info').catch(() => {});
    },
    loadPageCard: function(currentPage){
      let pageNumber = parseInt(currentPage) ? parseInt(currentPage) - 1 : 0;
      this.pageNumber = pageNumber;
      this.getDataCard();
    },
    getDataCard: function(){
      this.dateRange.startDate = new Date(this.dateRange.startDate.setHours(0, 0, 0));
      this.dateRange.endDate = new Date(this.dateRange.endDate.setHours(23, 59, 59));
      const payload = {
          cardStaffType: this.customer.cardStaffType,
          cardStatus: this.customer.cardStatus,
          startDate: this.dateRange.startDate,
          endDate: this.dateRange.endDate,
          pageSize: this.pageSize,
          pageNumber: this.pageNumber,
          sort: "id",
      }
      this.$vs.loading();
      this.$crm.post('/card-staff/find-by-condition', payload).then((res) => {
        this.listCards = res.data.content.map(el => {
          if (el.cardStaffType == 1) {
            if (el.employeeResponseDTO) {
              if (el.employeeResponseDTO) el.fullName = el.employeeResponseDTO.fullName;
              if (el.employeeResponseDTO.imageStore && el.employeeResponseDTO.imageStore.length > 0) el.urlImage = el.employeeResponseDTO.imageStore[0].url;
            }
            el.customerType = "Nhân viên"
          }
          if (el.cardStaffType === 0){
            if (el.customerResponseDTO) {
              el.fullName = el.customerResponseDTO.fullName;
              if (el.customerResponseDTO.imageStore && el.customerResponseDTO.imageStore.length > 0) el.urlImage = el.customerResponseDTO.imageStore[0].url;
            }
            el.customerType = this.$t('menuCustomer.customer')
          }
          if (el.cardStaffType === 2) el.customerType = this.$t('menuCustomer.unknown')
          return el;
        });
        let data = res.data;
        this.totalPages = data.totalPages;
        this.$vs.loading.close();
      }).catch((err) => {
        this.$vs.loading.close();
        return this.$vs.notify({
            title: 'Error',
            text: err.response.data.message || err.response.data.error_description || err.response.data.error,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
        });
      })
    },
    initScreen(){
      this.getDataCard();
    }
  },
  async created(){
    this.initScreen();
  }
}
</script>

<style scoped>

</style>